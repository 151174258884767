import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import GatsbyLink from "gatsby-link";
import { MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ListOfGalleries from "../components/ListOfGalleries";
import { useHomePageContent } from "../hooks/useHomePageContent";
import {
  container,
  homePageContentContainer,
  listOfGalleriesContainer,
  homePageContent,
  homePageTextContent,
  homePageImageContent
} from "./HomePage.module.css"

const HomePageTextContent = ({content}) => {
  if(!content) return '';
  
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b>{text}</b>,
    }
  };

  return (
    <div className={homePageTextContent}>
      {documentToReactComponents(JSON.parse(content.raw), options)}
      <GatsbyLink to="/about">
        <p>More about Liz Green</p>
      </GatsbyLink>
    </div>
  );
}

const HomePageImageContent = ({content}) => {
  return content ? 
    <GatsbyImage
      alt={`Image of Liz Green`}
      image={content.gatsbyImageData}
      className={homePageImageContent}
    /> :
    '';
}

const HomePageContent = ({content}) => {
  

  return (
    <div className={homePageContent}>
      <HomePageImageContent content={content.homePageImage}/>
      <HomePageTextContent content={content.homePageContent}/>
    </div>
  );
}

const HomePage = () => {
  const content = useHomePageContent();
  
  return (
    <div className={container}>
      <div className={homePageContentContainer}>
        <HomePageContent content={content}/>
      </div>
      <div className={listOfGalleriesContainer}>
        <ListOfGalleries galleries={content.galleries}/>
      </div>
    </div>
  );
}

export default HomePage
