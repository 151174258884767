import * as React from "react"
import Layout from "../components/Layout"
import HomePage from "../components/HomePage"
const IndexPage = () => {
  
  return (
    <Layout pageTitle="Home Page">
      <HomePage/>
    </Layout>
  )
}

export default IndexPage
