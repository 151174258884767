import { graphql, useStaticQuery } from "gatsby";

export const useHomePageContent = () => {
    const homePages = useStaticQuery(graphql`
        query {
            allContentfulHomePage(limit: 1) {
                edges {
                    node {
                        homePageContent {
                            raw
                        }
                        homePageImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                            )
                        }
                        galleries {
                            title
                            homePageArtwork {
                                mainImage {
                                    gatsbyImageData(placeholder: BLURRED)
                                    file {
                                        details {
                                            image {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`
    );

    return homePages.allContentfulHomePage.edges[0].node;
}