import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import {
  galleryList,
  galleryListItem,
  galleryPreview,
  galleryPreviewTitle,
  galleryPreviewImage,
  galleryPreviewImageReplacement
} from "./ListOfGalleries.module.css"

const GalleryPreviewImage = ({gallery}) => {
  if(gallery.homePageArtwork && gallery.homePageArtwork.mainImage) {  
    const altName = `Thumbnail image for gallery ${gallery.title}`;
    
    return <GatsbyImage 
      className={galleryPreviewImage}
      alt={altName}
      image={gallery.homePageArtwork.mainImage.gatsbyImageData}
    />
  } else {
    return <div className={galleryPreviewImageReplacement}></div>
  }
}

const GalleryPreview = ({gallery}) => {

  return (
    <Link to={`/galleries/${gallery.title.toLowerCase()}`} className={galleryPreview}>
      <GalleryPreviewImage gallery={gallery}/>
      <div className={galleryPreviewTitle}>
        <span>{gallery.title}</span>
      </div>
    </Link>
  );
}

const ListOfGalleries = ({galleries}) => {
  return (
    <ul className={galleryList}>
      {galleries.map(gallery => (
        <li key={gallery.title} className={galleryListItem}>
          <GalleryPreview gallery={gallery}/>
        </li>
      ))}
    </ul>
  );
}

export default ListOfGalleries;